<template>
  <v-container v-if="pageLoading" class="bg-color-theme px-3">
    <v-skeleton-loader type="list-item-two-line, card"></v-skeleton-loader>
  </v-container>

  <v-container v-else class="bg-color-theme px-3 fixed-cost-main">
    <v-card class="px-4">
      <v-expansion-panels flat v-model="expandPanelYear" multiple>
        <v-expansion-panel
          v-for="(panel, i) in getPanelData"
          :key="i"
          class="cost-panel"
        >
          <v-expansion-panel-header class="cost-panel-header">
            <span class="font-16px"> {{ panel.year + "年度" }} </span>
            <span class="text--secondary font-14px">{{
              panel.beginFiscalYear.format("YYYY/MM") +
                "~" +
                panel.endFiscalYear.format("YYYY/MM")
            }}</span>
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <v-expansion-panels flat v-model="panel.mPanelExpand">
              <v-expansion-panel
                v-for="(item, i) in panel.months"
                :key="i"
                class="cost-panel"
              >
                <v-expansion-panel-header class="font-14px cost-panel-month">
                  {{ item.month + "月" }}
                  <v-icon
                    role="button"
                    class="ml-3 cost-panel-head-icon d-flex-none"
                    small
                    @click="
                      $router.push({
                        name: 'costInput',
                        query: {
                          year_month: getYearMonthFormatted(
                            item.year,
                            item.month
                          )
                        }
                      })
                    "
                    >$edit</v-icon
                  >
                </v-expansion-panel-header>
                <v-expansion-panel-content
                  class="info-bg details rounded pt-3 font-12px"
                >
                  <div
                    class="ml-n1"
                    v-for="fixedCost in getFixedCosts"
                    :key="fixedCost.id"
                  >
                    <span
                      v-if="
                        fixedCost.year_month ===
                          getYearMonthFormatted(item.year, item.month)
                      "
                    >
                      <div>
                        人件費(正社員)：{{
                          getPriceFormat(fixedCost.labor_cost_regular_employees)
                        }}
                      </div>
                      <div>家賃：{{ getPriceFormat(fixedCost.rent) }}</div>
                      <div class="d-flex align-center">
                        <span class="font-12px">
                          プラットフォーム手数料：
                        </span>
                        <span>{{
                          getPriceFormat(fixedCost.platform_fee)
                        }}</span>
                        <v-icon
                          x-small
                          role="button"
                          @click="helpModal('PlatformFeeModal')"
                          class="ml-1"
                          >$infoIcon</v-icon
                        >
                      </div>
                      <div class="d-flex align-center">
                        <span>
                          その他：{{ getPriceFormat(fixedCost.others) }}</span
                        >
                        <v-icon
                          role="button"
                          x-small
                          class="ml-1"
                          @click="helpModal('OtherCostModal')"
                          >$infoIcon</v-icon
                        >
                      </div>
                    </span>
                  </div>
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </v-card>
  </v-container>
</template>

<script>
import { mapGetters } from "vuex";
import dayjs from "@/plugins/dayjs";

export default {
  name: "FixedCostList",
  data() {
    return {
      pageLoading: true,
      shop_id: null,
      currentYear: dayjs().format("YYYY")
    };
  },
  created() {
    let this_this = this;
    this.$root.$on("shopChanged", function() {
      this_this.getDataFromApi();
    });
    this.getDataFromApi();
  },
  computed: {
    ...mapGetters(["getFixedCosts"]),
    getShop() {
      return this.$store.getters.getFirstShopObject;
    },

    currentFiscalYear() {
      let now = dayjs();
      let month = now.month();
      let fiscalYearMonth = this.getShop.start_of_fiscal_year;
      let beginFiscalYear = null;
      let endFiscalYear = null;
      let currentFiscalYear = null;

      if (month < fiscalYearMonth) {
        beginFiscalYear = now
          .subtract(1, "year")
          .month(fiscalYearMonth)
          .subtract(1, "month");
      } else {
        beginFiscalYear = now.month(fiscalYearMonth).subtract(1, "month");
      }

      currentFiscalYear = beginFiscalYear.format("YYYY");
      endFiscalYear = beginFiscalYear.add(1, "year").subtract(1, "month");

      return {
        month: month,
        fiscalYearMonth: fiscalYearMonth,
        year: currentFiscalYear,
        beginFiscalYear: beginFiscalYear,
        endFiscalYear: endFiscalYear
      };
    },

    getPanelData() {
      let count = 0;
      let mPanelExpand = null;
      let currentMonth = dayjs().format("MM");
      let currentYear = dayjs().format("YYYY");
      let beginYear = parseInt(this.currentFiscalYear.year);
      let endYear = beginYear - 1;
      let endFiscalYear = "";
      let panels = [];
      // Generate month values (1-12)
      let months = [...Array(12).keys()].map(i => i + 1);
      // Shift month index so it starts from the fiscal year
      months = this.shiftLeft(months, this.getShop.start_of_fiscal_year);

      while (endYear <= beginYear) {
        let beginFiscalYear = this.currentFiscalYear.beginFiscalYear.year(
          endYear
        );

        if (
          this.currentFiscalYear.month < this.currentFiscalYear.fiscalYearMonth
        ) {
          endFiscalYear = this.currentFiscalYear.endFiscalYear.year(
            endYear + 1
          );
        } else {
          endFiscalYear = this.currentFiscalYear.endFiscalYear.year(endYear);
        }

        if (endFiscalYear.format("YYYY") == currentYear) {
          months.forEach(panelMonth => {
            count += 1;
            if (currentMonth == panelMonth) {
              mPanelExpand = count - 1;
            }
          });
        } else {
          mPanelExpand = null;
        }

        panels.push({
          beginFiscalYear: beginFiscalYear,
          endFiscalYear: endFiscalYear,
          year: endYear,
          mPanelExpand: mPanelExpand,
          // Advance trailing months to next year
          months: months.map(month => {
            return {
              month: month,
              year:
                month < this.getShop.start_of_fiscal_year
                  ? endYear + 1
                  : endYear
            };
          })
        });

        endYear++;
      }

      panels.sort((a, b) => (a.year < b.year ? 1 : -1));
      return panels;
    },
    expandPanelYear() {
      let array = [];
      let count = 0;
      this.getPanelData.forEach(panel => {
        count += 1;
        if (panel.year == this.currentFiscalYear.year) {
          array.push(count - 1);
        }
      });

      return array;
    }
  },
  methods: {
    helpModal(component) {
      this.$store.commit("showModal", { component });
    },

    getDataFromApi() {
      this.shop_id = this.$store.getters.getFirstShopObject.id;
      let param = {
        id: this.$store.getters.getFirstShopObject.id //Shop Id
      };

      this.$store.dispatch("FIXEDCOST_GET_ALL", param).finally(() => {
        this.pageLoading = false;
      });

      this.$store.dispatch("SHOP_GET", param).finally(() => {
        this.pageLoading = false;
      });
    },

    getPriceFormat(amount) {
      return this.priceFormat(amount);
    },

    getYearMonthFormatted(year, month) {
      return `${year}-${String(month).padStart(2, "0")}`;
    },

    shiftLeft(arr = [], n) {
      for (let index = 0; index < n - 1; index++) {
        arr.push(arr.shift());
      }
      return arr;
    }
  }
};
</script>

<style lang="scss" scope src="./style.scss"></style>
